<template>
  <div class="elite-tabs-wrapper-content">
    <h3 class="mb-4">{{ organization.name }} - {{ $t("COMMON.USERS") }}</h3>
    <div class="popup-table-header">
      <h3>{{ $t("COMMON.USERS") }}</h3>
      <base-button
        class="add"
        icon
        size="sm"
        @click="addUser"
        v-if="this.$currentUserCan(this.$permissions.PERM_CREATE_USERS)"
      >
        <span class="btn-inner--icon">
          <i class="fa-regular fa-circle-plus"></i>
        </span>
        <span class="btn-inner--text">
          {{ $t("USERS.ADD_USER") }}
        </span>
      </base-button>
    </div>
    <user-list-table :filterOrganization="organization.id" />
  </div>
</template>

<script>
import { QUERY_ACTIONS_ADD } from "../../../../../constants/common";
import UserListTable from "../../UserManagement/partials/UserListTable.vue";
export default {
  name: "organization-view-users",

  components: { UserListTable },

  props: ["organization"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {
    addUser() {
      this.$router.push({
        name: "List Users",
        query: {
          action: QUERY_ACTIONS_ADD,
          organization_id: this.organization.id,
          reseller_id: this.organization?.reseller?.id,
        },
      });
    },
  },

  mounted() {},

  watch: {
    organization(organization) {},
  },
};
</script>
