<template>
  <form
    class="add-form"
    ref="profile_form"
    @submit.prevent="handleSubmit"
    @keydown.enter.prevent="() => {}"
  >
    <div class="form-wrapper full">
      <image-selector
        :defaultImage="organization.logo"
        ressource_name="organizations"
        :ressource_id="organization.id ? organization.id : 0"
        field="logo"
        @imageChanged="
          (file_url) => {
            organization.logo = file_url;
            onFormChanged();
          }
        "
      />
      <validation-error :errors="apiValidationErrors.attachment" />
    </div>

    <div
      class="form-wrapper"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_RESELLERS)"
    >
      <base-input
        :label="`${$t('COMMON.RESELLER')} (*)`"
        :placeholder="$t('COMMON.RESELLER')"
      >
        <reseller-selector
          :reseller="organization.reseller.id"
          :filterable="true"
          :showAll="false"
          @resellerChanged="
            (resellerId) => {
              organization.reseller.id = resellerId;
              organization.owner.id = null;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.reseller" />
    </div>

    <div class="form-wrapper">
      <base-input
        :label="`${$t('COMMON.NAME')} (*)`"
        :placeholder="$t('COMMON.NAME')"
        v-model="organization.name"
        @change="onFormChanged()"
      />
      <validation-error :errors="apiValidationErrors.name" />
    </div>

    <div class="form-wrapper full">
      <base-input
        type="textarea"
        :label="$t('COMMON.EXCERPT')"
        :placeholder="$t('COMMON.EXCERPT')"
      >
        <html-editor v-model="organization.excerpt" @change="onFormChanged()">
        </html-editor>
      </base-input>
      <validation-error :errors="apiValidationErrors.excerpt" />
    </div>

    <div class="form-wrapper full">
      <base-input
        :label="`${$t('COMMON.EMAIL')} (*)`"
        :placeholder="$t('COMMON.EMAIL')"
        v-model="organization.email"
        @change="onFormChanged()"
      />
      <validation-error :errors="apiValidationErrors.email" />
    </div>

    <!-- Phone number -->
    <div class="form-wrapper full">
      <div class="row equal">
        <div class="equal-item type">
          <base-input :label="`${$t('COMMON.TYPE')}`">
            <el-select
              :label="$t('COMMON.TYPE')"
              :placeholder="$t('COMMON.TYPE')"
              v-model="organization.phone_type"
              @change="
                (type) => {
                  organization.phone_type = type;
                  onFormChanged();
                }
              "
            >
              <el-option
                v-for="(value, key) in phoneTypesOptions"
                :key="key"
                :value="value"
                :label="$t(`COMMON.PHONE_TYPE_${value}`)"
              />
            </el-select>
          </base-input>
        </div>
        <div class="equal-item phone">
          <base-input :label="`${$t('COMMON.PHONE')}`">
            <phone-number-input
              :phoneNumber="organization.phone"
              @phoneNumberChanged="
                (phone) => {
                  organization.phone = phone;
                  onFormChanged();
                }
              "
            />
          </base-input>
          <validation-error :errors="apiValidationErrors.phone" />
        </div>
        <div class="equal-item extension">
          <base-input
            :label="`${$t('COMMON.EXTENSION')}`"
            v-model="organization.phone_extension"
            @change="
              () => {
                onFormChanged();
              }
            "
            :inputClasses="'extension-input'"
          />
          <validation-error :errors="apiValidationErrors.phone_extension" />
        </div>
      </div>
    </div>

    <!-- Other phone numbers -->
    <div class="form-wrapper full">
      <base-input :label="`${$t('COMMON.OTHER_PHONES')}`">
        <phone-numbers-selector
          :phoneNumbers="organization.other_phones"
          @phoneNumbersChanged="
            (phoneNumbers) => {
              organization.other_phones = phoneNumbers;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.other_phones" />
    </div>

    <div class="form-wrapper full">
      <base-input
        :label="`${$t('COMMON.ADDRESS')} (*)`"
        :placeholder="$t('COMMON.ADDRESS')"
        v-model="organization.address"
        @change="onFormChanged()"
      />
      <validation-error :errors="apiValidationErrors.address" />
    </div>

    <div class="form-wrapper taxes full">
      <taxes-selector
        :label="$t('COMMON.DEFAULT_TAXES')"
        :taxes="organization.taxes"
        @taxesChanged="
          (taxes) => {
            organization.taxes = taxes;
            onFormChanged();
          }
        "
      />
      <validation-error :errors="apiValidationErrors.taxes" />
    </div>

    <h2 class="col-12 mb-3" v-if="false">{{ $t("COMMON.BILLING_ADDRESS") }}</h2>

    <billing-informations-form
      v-if="false"
      :billingInformationsData="organization"
      :formErrors="formErrors"
      @onChangeBillingInformations="billingInformationsChanged"
    />

    <div class="submit-wrapper">
      <base-button
        type="button"
        class="btn btn-sm elite-submit"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading"></i>
        {{
          organization.id
            ? $t("ORGANIZATIONS.EDIT_ORGANIZATION")
            : $t("ORGANIZATIONS.ADD_ORGANIZATION")
        }}
      </base-button>
    </div>
  </form>
</template>
<script>
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import models from "@/constants/models";
import { phoneTypesOptions } from "@/constants/common";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import ResellerSelector from "@/components/ResellerSelector.vue";
import ImageSelector from "@/components/ImageSelector.vue";
import TaxesSelector from "@/components/TaxesSelector.vue";
import BillingInformationsForm from "@/components/BillingInformationsForm.vue";
import PhoneNumberInput from "@/components/PhoneNumberInput.vue";
import PhoneNumbersSelector from "@/components/PhoneNumbersSelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    HtmlEditor,
    BaseInput,
    ValidationError,
    ResellerSelector,
    ImageSelector,
    TaxesSelector,
    BillingInformationsForm,
    [Select.name]: Select,
    [Option.name]: Option,
    PhoneNumberInput,
    PhoneNumbersSelector,
  },

  mixins: [formMixin],

  props: ["organizationData", "formErrors", "loading"],

  data() {
    return {
      organization: { ...this.organizationData },
      permissions: [],
      models: models,
      phoneTypesOptions,
    };
  },

  created() {},

  methods: {
    async handleSubmit() {
      let organizationData = cloneDeep(this.organization);
      if (organizationData.owner) {
        if (organizationData.owner.id == null) {
          organizationData.owner = null;
        }
      }
      organizationData = this.$fillUserResellerData(organizationData);

      organizationData.billing_entity_type =
        organizationData.billing_entity_type ?? "COMPANY";
      organizationData.billing_company_name =
        organizationData.billing_company_name ?? organizationData.name;
      organizationData.billing_email =
        organizationData.billing_email ?? organizationData.email;
      organizationData.billing_address =
        organizationData.billing_address ?? organizationData.address;
      organizationData.billing_country =
        organizationData.billing_country ?? "N/A";
      organizationData.billing_zipcode =
        organizationData.billing_zipcode ?? "N/A";
      organizationData.billing_firstname =
        organizationData.billing_firstname ?? "N/A";
      organizationData.billing_lastname =
        organizationData.billing_lastname ?? "N/A";
      organizationData.billing_city = organizationData.billing_city ?? "N/A";

      this.$emit("organizationSubmitted", organizationData);
    },

    billingInformationsChanged(billingInformations) {
      this.organization = { ...this.organization, ...billingInformations };
      this.onFormChanged();
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    organizationData(organizationData) {
      if (organizationData) {
        this.organization = {
          ...this.organization,
          ...cloneDeep(organizationData),
        };
      }
    },
  },
};
</script>
