<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!organization">
      <span class="loader"></span>
    </span>
    <div v-if="organization" class="elite-tabs-wrapper">
      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper"
        tabNavClasses="nav elite-tabs"
        :value="currentTab"
        @onTabChanged="tabChanged"
      >
        <tab-pane title="global" id="1" :active="true">
          <span slot="title">
            <i class="fa-regular fa-list"></i>
            {{ $t("COMMON.GLOBAL") }}
          </span>
          <organization-view-global
            :organization="organization"
            @onUpdateOrganization="onUpdateOrganization"
          />
        </tab-pane>

        <tab-pane
          title="authorizations"
          id="2"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_PERMISSIONS)"
        >
          <span slot="title">
            <i class="fa-regular fa-lock-keyhole"></i>
            {{ $t("ORGANIZATIONS.AUTHORIZATIONS") }}
          </span>
          <organization-view-authorizations :organization="organization" />
        </tab-pane>

        <tab-pane
          title="options"
          id="3"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_OPTIONS)"
        >
          <span slot="title">
            <i class="fa-regular fa-gear"></i>
            {{ $t("COMMON.OPTIONS") }}
          </span>
          <organization-view-options :organization="organization" />
        </tab-pane>

        <tab-pane
          title="subscriptions"
          id="4"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_SUBSCRIPTIONS)"
        >
          <span slot="title">
            <i class="fa-regular fa-address-card"></i>
            {{ $t("COMMON.SUBSCRIPTIONS") }}
          </span>
          <organization-view-subscriptions :organization="organization" />
        </tab-pane>

        <tab-pane
          title="users"
          id="5"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_USERS)"
        >
          <span slot="title">
            <i class="fa-light fa-users"></i>
            {{ $t("COMMON.USERS") }}
          </span>
          <organization-view-users :organization="organization" />
        </tab-pane>

        <tab-pane
          title="roles"
          id="6"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ROLES)"
        >
          <span slot="title">
            <i class="fa-regular fa-graduation-cap"></i>
            {{ $t("COMMON.ROLES") }}
          </span>
          <organization-view-roles :organization="organization" />
        </tab-pane>

        <tab-pane
          title="files"
          id="998"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_FILES)"
        >
          <span slot="title">
            <i class="far fa-file-alt"></i>
            {{ $t("COMMON.FILES") }}
          </span>
          <div class="elite-tabs-wrapper-content">
            <list-file-component :object="getFilesObject()" />
          </div>
        </tab-pane>

        <tab-pane
          title="logs"
          id="999"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
        >
          <span slot="title">
            <i class="fa fa-history"></i>
            {{ $t("COMMON.LOGS") }}
          </span>
          <organization-view-logs :organization="organization" />
        </tab-pane>
      </tabs>
    </div>

    <modal
      :show.sync="showSubscriptionAddModal"
      modal-classes="modal-secondary"
      size="lg"
    >
      <div class="col-12 text-center mb-2 mt-5">
        <h2>{{ $t("ORGANIZATIONS.SELECT_A_PACKAGE") }}</h2>
      </div>

      <form class="" @submit.prevent="generateSubscriptionInvoice">
        <base-input :label="$t('COMMON.PACKAGES')">
          <package-selector
            @packageChanged="
              (packageId) => (addSubscriptionPackageId = packageId)
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.package" />

        <div class="submit-wrapper">
          <base-button
            type="button"
            class="btn btn-sm elite-submit"
            native-type="submit"
            :disabled="loading"
          >
            <i class="fas fa-spinner fa-spin" v-if="loading"></i>
            {{ $t("ORGANIZATIONS.ADD_SUBSCRIPTION") }}
          </base-button>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import swal from "sweetalert2";
import { cloneDeep } from "lodash";
import { Tabs, TabPane } from "@/components";
import formMixin from "@/mixins/form-mixin";
import requestErrorMixin from "@/mixins/request-error-mixin";
import PackageSelector from "@/components/PackageSelector.vue";
import ValidationError from "@/components/ValidationError.vue";
import ListFileComponent from "@/components/File/ListFileComponent.vue";
import OrganizationViewGlobal from "../partials/OrganizationViewGlobal.vue";
import OrganizationViewUsers from "../partials/OrganizationViewUsers.vue";
import OrganizationViewRoles from "../partials/OrganizationViewRoles.vue";
import OrganizationViewLogs from "../partials/OrganizationViewLogs.vue";
import OrganizationViewSubscriptions from "../partials/OrganizationViewSubscriptions.vue";
import OrganizationViewAuthorizations from "../partials/OrganizationViewAuthorizations.vue";
import OrganizationViewOptions from "../partials/OrganizationViewOptions.vue";
import hasTabs from "@/mixins/has-tabs";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    OrganizationViewGlobal,
    OrganizationViewUsers,
    OrganizationViewRoles,
    OrganizationViewLogs,
    OrganizationViewSubscriptions,
    OrganizationViewAuthorizations,
    OrganizationViewOptions,
    ListFileComponent,
    PackageSelector,
    ValidationError,
  },

  mixins: [formMixin, requestErrorMixin, hasTabs],

  props: {
    organizationId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      organization: null,
      loading: false,
      showSubscriptionAddModal: false,
      addSubscriptionPackageId: null,
    };
  },

  computed: {},

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        await this.$store.dispatch("organizations/get", this.organizationId);
        this.organization = this.$store.getters["organizations/organization"];
      } catch (error) {
        this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async generateSubscriptionInvoice() {
      this.loading = true;
      try {
        const invoice = await this.$store.dispatch(
          "organizations/generateSubscriptionInvoice",
          {
            organizationId: this.organization.id,
            data: { package: this.addSubscriptionPackageId },
          }
        );

        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("ORGANIZATIONS.SUBSCRIPTION_INVOICE_ADDED"),
        });
        this.loading = false;

        this.$router.push(this.$objectViewRoute(invoice));
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
        this.setApiValidation(error.response.data.errors);
      }
    },

    getFilesObject() {
      return {
        ...cloneDeep(this.organization),
        organization: cloneDeep(this.organization),
      };
    },

    onUpdateOrganization() {
      this.$emit("onEditOrganization", this.organization);
    },
  },
};
</script>
