<template>
  <el-select
    v-model="objectsModel"
    @change="objectsChanged"
    autocomplete="new-password"
    :placeholder="placeholder"
    :filterable="true"
    :multiple="true"
    :disabled="disabled"
    remote
    :remote-method="getObjects"
    :loading="loading"
  >
    <el-option
      v-for="object in objectsList"
      :key="object.id"
      :value="object.id"
      :label="`${object[objectSearchField]}`"
    >
    </el-option>
  </el-select>
</template>

<script>
import { camelCase, snakeCase } from "lodash";
import { Option, Select } from "element-ui";

export default {
  name: "object-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    objects: {
      type: Array,
      description: "Object id",
    },
    objectType: {
      type: String,
      required: true,
      description: "Object type",
    },
    objectSearchField: {
      type: String,
      required: true,
      description: "Key of the name of the object",
    },
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
  },

  data() {
    return {
      objectsModel: this.objects.map((item) => item.id),
      objectsList: {},
    };
  },

  setup() {},

  computed: {
    placeholder() {
      return this.$t(`COMMON.${snakeCase(this.objectType).toUpperCase()}`);
    },
  },

  created() {
    this.getObjects(
      null,
      this.objects.map((item) => item.id)
    );
  },

  methods: {
    async getObjects(query = null, objects = null) {
      try {
        this.loading = true;
        let params = {
          sort: this.objectSearchField,
          filter: {
            ...(query ? { search: query } : {}),
            ...(objects ? { with_ids: objects } : {}),
          },
          page: {
            number: 1,
            size: 10,
          },
        };

        if (this.filterOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.filterOrganization,
            },
          };
        }

        await this.$store.dispatch(
          `${camelCase(this.objectType)}/list`,
          params
        );
        const objectsArr = await this.$store.getters[
          `${camelCase(this.objectType)}/list`
        ];
        this.objectsList = {};
        objectsArr.forEach((object) => {
          this.objectsList[object.id] = object;
        });
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    objectsChanged(objects) {
      this.$emit(
        "objectsChanged",
        objects.map((item) => {
          return { id: item, type: this.objectType };
        })
      );
    },
  },

  watch: {
    objects(objects) {
      this.objectsModel = objects.map((item) => item.id);
      this.getObjects(null, this.objectsModel);
    },
    filterOrganization(filterOrganization) {
      this.getObjects();
    },
  },
};
</script>
